import styled from 'styled-components';
import { flexbox, layout, space, typography } from 'styled-system';

const Box = styled.div`
  ${flexbox};
  ${layout};
  ${space};
  ${typography};
`;

Box.propTypes = {
  ...flexbox.propTypes,
  ...layout.propTypes,
  ...space.propTypes,
  ...typography.propTypes,
};

export default Box;
