import styled from 'styled-components';

const Inner = styled.div`
  max-width: ${(props) =>
    props.maxWidth ? props.maxWidth + 'px' : props.theme.maxWidth};
  width: ${({ width }) => (width ? width + '%' : '90%')};
  margin: 0 auto;
`;

export default Inner;
