import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import ContactInfo from './ContactInfo';
import Inner from './Styles/Inner';
import FooterColumn from './FooterColumn';
import Logo from './Logo';
import Typography from './Styles/Typography';
import Form from '../components/Styles/Form';
import Button from '../components/Buttons/Button';
import Card from '../components/Styles/Card';

const Footer = (props) => {
  const { location } = props;
  return (
    <footer>
      <Inner>
        <FooterBottom>
          <FooterColumn>
            <Logo />
            <ContactInfo />
            <Typography as="span">
              ©{new Date().getFullYear()}, Alternative Dev
            </Typography>
          </FooterColumn>
          <FooterColumn heading="Services">
            <ul>
              <li>
                <Link to="/services/web-design/">Web Design</Link>
              </li>
              <li>
                <Link to="/services/support-maintenance/">
                  Support &amp; Maintenance
                </Link>
              </li>
              <li>
                <Link to="/services/web-design/">Managed Hosting</Link>
              </li>
              <li>
                <Link to="/services/white-label-websites/">
                  White Label Websites
                </Link>
              </li>
            </ul>
          </FooterColumn>
          <FooterColumn heading="Who We Help">
            <ul>
              <li>Business Owners</li>
              <li>Agencies</li>
              <li>Freelancers</li>
              <li>Startups</li>
            </ul>
          </FooterColumn>
          <FooterColumn heading="Company">
            <ul>
              <li>
                <Link to="/company/">About Us</Link>
              </li>
              <li>
                <Link to="/contact/">Contact Us</Link>
              </li>
              <li>Privacy Policy</li>
            </ul>
          </FooterColumn>
        </FooterBottom>
      </Inner>
    </footer>
  );
};

const FooterTop = styled.div`
  border-bottom: solid 1px ${(props) => props.theme.colors.offWhite};
  padding-bottom: 4em;
  form {
  }
`;

const FooterBottom = styled.div`
  padding: 64px 16px;
  display: flex;
  flex-wrap: wrap;
  .logo {
    max-width: 200px;
  }
`;

export default Footer;
