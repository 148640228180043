import styled from 'styled-components';
import { color, space, typography, layout } from 'styled-system';

const Typography = styled.p`
  ${typography};
  ${color};
  ${space};
  ${layout};
`;

export default Typography;
