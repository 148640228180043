import React from 'react';
import styled from 'styled-components';
import ClockIcon from './Icons/Fa/Thin/ClockIcon';
import EnvelopeIcon from './Icons/Fa/Thin/EnvelopeIcon';
import PhoneIcon from './Icons/Fa/Thin/PhoneIcon';

function ContactInfo() {
  return (
    <Wrap>
      <br />
      <div className="single-line-text-with-icon">
        <PhoneIcon />
        <span>(978) 592-2118‬ </span>
      </div>
      <br />
      <div>
        <a
          href="mailto:info@alternativedev.com"
          className="single-line-text-with-icon"
        >
          <EnvelopeIcon />
          <span>info@alternativedev.com</span>
        </a>
      </div>
      <div>
        <a
          href="mailto:support@alternativedev.com"
          className="single-line-text-with-icon"
        >
          <EnvelopeIcon />
          <span>support@alternativedev.com</span>
        </a>
      </div>
      <div>
        <a
          href="mailto:billing@alternativedev.com"
          className="single-line-text-with-icon"
        >
          <EnvelopeIcon />
          <span>billing@alternativedev.com</span>
        </a>
      </div>
      <br />
      <h4>Address</h4>
      <hr />
      <address>
        253 Speen Street
        <br />
        Natick, MA 01760
        <br />
        USA
      </address>
    </Wrap>
  );
}
const Wrap = styled.div`
  h4 {
    margin: 0;
  }
  p {
    margin: 0 0 0.5em 0;
  }
  hr {
    border: 1px dotted whitesmoke;
  }
  svg {
    margin-right: 0.5em;
    width: 1.2em;
  }
  .single-line-text-with-icon {
    display: flex;
    align-items: center;
  }
`;

export default ContactInfo;
