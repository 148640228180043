import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import Nav from './Nav';
import Inner from './Styles/Inner';
import Logo from './Logo';
import NavMobile from './NavMobile';
import BarsIcon from './Icons/Fa/Thin/BarsIcon';
import XmarkIcon from './Icons/Fa/Thin/XmarkIcon';
import ButtonGradient from './Buttons/ButtonGradient';
import Box from './Styles/Box';

const Header = React.forwardRef(({ siteTitle }, ref) => {
  const [navActive, toggleNav] = useState(false);
  return (
    <HeaderStyles ref={ref} navActive={navActive}>
      <InnerStyles>
        <NavContainer>
          <LogoWrap style={{ margin: 0 }}>
            <Link to="/" alt="Alternative Dev">
              <Logo />
            </Link>
          </LogoWrap>
          <Nav navActive={navActive} />
          <CTAStyles>
            <Link to="/contact/">
              <ButtonGradient>Get Free Estimate</ButtonGradient>
            </Link>
          </CTAStyles>
          <div
            className="toggle"
            role="button"
            aria-label="Menu Menu"
            tabIndex={0}
            onKeyPress={() => {}}
            onClick={() => toggleNav(!navActive)}
          >
            {!navActive ? <BarsIcon /> : <XmarkIcon />}
          </div>
        </NavContainer>
      </InnerStyles>
      <NavMobile navActive={navActive} />
    </HeaderStyles>
  );
});

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

const HeaderStyles = styled.header`
  display: flex;
  align-items: center;
  background: var(--light);
  height: 75px;
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 9999;
  .toggle {
    width: ${(props) => (!props.navActive ? '32px' : '22px')};
    z-index: 99999;
    cursor: pointer;
    .icon {
      fill: url(#icon-gradient) white;
    }
  }
  @media (min-width: 81.25em) {
    .toggle {
      display: none;
    }
  }
`;

const CTAStyles = styled(Box)`
  display: none;
  @media (min-width: 81.25em) {
    & {
      display: block;
    }
  }
`;

const InnerStyles = styled(Inner)`
  max-width: 100%;
  width: 100%;
  padding: 0 1em;
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoWrap = styled.span`
  max-width: 100px;
`;

export default Header;
