
		import React from 'react';

		function PhoneIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 512, 512">
					<path d="M493.1 351.2L384.6 304.7c-12.78-5.531-27.78-1.806-36.47 8.975l-44.08 53.8c-69.25-34-125.5-90.3-159.5-159.5l53.83-44.06C209.1 155.1 212.8 140.2 207.3 127.5L160.8 18.92c-6.094-13.91-21.13-21.53-35.82-18.12L24.22 24.05C9.969 27.3 0 39.85 0 54.5c0 252.3 205.2 457.5 457.5 457.5c14.67 0 27.19-9.968 30.46-24.22l23.25-100.8C514.6 372.4 506.1 357.2 493.1 351.2zM495.6 383.4l-23.25 100.8C470.8 491.1 464.6 496 457.5 496C214 496 16 297.9 16 54.5c0-7.156 4.859-13.25 11.81-14.88l100.8-23.25c7.203-1.656 14.61 2.125 17.58 8.938l46.52 108.5c2.66 6.244 .7871 13.5-4.406 17.75l-63.53 52c62.32 133.5 168.7 176.7 183.8 183.8l52.02-63.53c4.219-5.25 11.52-7.062 17.73-4.375l108.5 46.5C493.5 368.9 497.3 376.3 495.6 383.4z" />
				</svg>
			);
		}

		export default PhoneIcon;
	