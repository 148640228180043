import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

function SectionDivider(props) {
  const { fillColor } = props;
  // TODO: Fix -1 viewBox with better svg design?
  return (
    <SvgStyles
      width="1440"
      height="140"
      viewBox="0 -1 1440 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      fillColor={fillColor}
      aria-hidden="true"
    >
      <g clip-path="url(#clip0)">
        <path d="M0 140H1440V43.9998C1152 184 1083.91 20.2265 863.5 19.9998C645.087 19.775 288 184 0 43.9998V140Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="1440" height="140" transform="matrix(1 0 0 -1 0 140)" />
        </clipPath>
      </defs>
    </SvgStyles>
  );
}

const SvgStyles = styled.svg`
  margin-top: 2em;
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  fill: ${({ fillColor }) => (fillColor ? fillColor : 'var(--light)')};
`;

export default SectionDivider;
