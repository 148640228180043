import styled from 'styled-components';
import { color, space, typography, layout } from 'styled-system';

const Heading = styled.h2`
  ${typography};
  ${color};
  ${space};
  ${layout};
`;

export default Heading;
