/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { layout, space } from 'styled-system';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyles from './Styles/GlobalStyles';
import Header from './Header';
import Footer from './Footer';
import FooterForm from './FooterForm';

const breakpoints = ['40em', '52em', '64em', '80em'];
const fontSizes = [12, 14, 16, 20, 24, 32];
const spacing = [0, 4, 8, 16, 32, 64];

// aliases
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

const theme = {
  colors: {
    blue: `#163974`,
    black: '#282828',
    gray: '#3A3A3A',
    lightGray: '#E1E1E1',
    offWhite: '#F6F6F6',
    orange: '#FFB81C',
    purple: '#8A00ED',
    lightBlue: '#00BDFF',
    lightGreen: '#00FFAA',
  },
  maxWidth: '1240px',
  bs: '0 12px 24px 0 rgba(0, 0, 0, 0.09)',
  breakpoints,
};

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const headerRef = useRef(null);
  async function getHeaderHeight() {
    const res = await headerRef;
    return res;
  }

  const height = getHeaderHeight();

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <TopBorder />
        {/* TODO: Should comeup with a better solution than this for icon gradient fix */}
        <svg
          style={{ width: '0', height: '0', position: 'absolute' }}
          aria-hidden="true"
          focusable="false"
        >
          <linearGradient id="icon-gradient" x2="1" y2="1">
            <stop offset="25%" stopColor="var(--secondary)" />
            <stop offset="100%" stopColor="var(--primary)" />
          </linearGradient>
        </svg>
        <Header ref={headerRef} siteTitle={data.site.siteMetadata.title} />
        <div>
          <main>{children}</main>
        </div>
        {!location?.pathname?.match(/contact/g) && <FooterForm />}
        <Footer location={location} />
      </>
    </ThemeProvider>
  );
};

const TopBorder = styled.div`
  background: var(--linearGradientMixed);
  height: 4px;
  width: 100%;
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
