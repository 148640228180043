import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';
import ButtonGradient from './Buttons/ButtonGradient';
import NavItemLink from './NavItemLink';
import Box from './Styles/Box';
import Grid from './Styles/Grid';
import Heading from './Styles/Heading';

const Nav = (props) => {
  const query = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phoneNumber
          menus {
            mainMenu {
              name
              slug
              children {
                name
                slug
              }
            }
          }
        }
      }
    }
  `);
  const menuLinks = query.site.siteMetadata.menus.mainMenu;
  return (
    <Wrap>
      <ul>
        {menuLinks.map((link, index) => (
          <NavItem key={index}>
            <NavItemLink to={link.slug} linkText={link.name} />
            {link.children && (
              <NavDropdown className="sub-menu">
                <NavDropdown__Column>
                  <NavDropdown__List>
                    {link.children.map((childLink, index) => (
                      <NavDropdown__ListItem key={index}>
                        <Link to={childLink.slug} activeClassName="active">
                          {childLink.name}
                        </Link>
                      </NavDropdown__ListItem>
                    ))}
                  </NavDropdown__List>
                </NavDropdown__Column>
              </NavDropdown>
            )}
          </NavItem>
        ))}
      </ul>
    </Wrap>
  );
};

const Wrap = styled.nav`
  display: none;
  justify-content: space-between;
  align-items: center;
  ul {
    margin: 0;
    padding: 0;
  }
  a {
    padding: 1.2em;
    text-decoration: none;
    color: var(--textColor);
  }
  a:hover {
    color: var(--purple);
  }
  @media (min-width: 81.25em) {
  & {
    display: flex;
    flex: 1;
  }
`;

const NavItem = styled.li`
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0;
  color: var(--textColor);
  font-weight: 700;
  &:after {
    /* content: '\u02C7'; */
    position: absolute;
    right: 32px;
    top: 50%;
    font-size: 3em;
    color: var(--secondary);
  }
  :hover .sub-menu {
    visibility: visible;
    opacity: 1;
    /* display: block; */
  }
  @media (max-width: 960px) {
    display: block;
    a {
      color: var(--textColor);
      display: block;
    }
  }
`;

const NavDropdown = styled.div`
  position: absolute;
  top: 2.4em;
  left: 50%;
  transform: translate(-50%);
  border-radius: 5px;
  z-index: 25;
  background: white;
  border-top: 2px solid var(--purple);
  white-space: nowrap;
  transition: opacity 0.2s ease-in-out, visibility 0.2s;
  visibility: hidden;
  opacity: 0;
  padding: 2em;
  box-shadow: var(--boxShadow);
  a {
    display: block;
    padding: 0;
  }
  :before {
    content: '';
    position: absolute;
    top: -1px;
    left: 50%;
    width: 12px;
    height: 12px;
    transform: translate(-50%, -50%) rotate(45deg);
    border-radius: 3px 0 0 0;
    background: white;
    box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.09);
    border-top: 2px solid var(--purple);
    border-left: 2px solid var(--purple);
    z-index: -1;
  }
`;
const NavDropdown__Column = styled(Box)``;
const NavDropdown__List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;
const NavDropdown__ListItem = styled.li`
  margin: 0;
  & + & {
    margin-top: 0.75em;
  }
`;

Nav.propTypes = {
  links: PropTypes.array,
};

export default Nav;
