import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const NavItemLink = (props) => (
  <Link to={props.to}>
    {props.icon && <span></span>}
    <span>{props.linkText}</span>
  </Link>
);

export default NavItemLink;
