import styled from 'styled-components';
import { grid, space, layout, flexbox } from 'styled-system';

const Grid = styled.div`
  display: grid;
  ${grid};
  ${space};
  ${layout};
  ${flexbox}
`;

export default Grid;
