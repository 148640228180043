
		import React from 'react';

		function EnvelopeIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 512, 512">
					<path d="M448 64H64C28.65 64 0 92.65 0 128v256c0 35.35 28.65 64 64 64h384c35.35 0 64-28.65 64-64V128C512 92.65 483.3 64 448 64zM496 384c0 26.47-21.53 48-48 48H64c-26.47 0-48-21.53-48-48V175.1l206.5 165.2C232 347.8 243.9 352 256 352s24-4.188 33.5-11.77L496 175.1V384zM496 154.6l-216.5 173.2c-13.31 10.66-33.69 10.66-47 0L16 154.6V128c0-26.47 21.53-48 48-48h384c26.47 0 48 21.53 48 48V154.6z" />
				</svg>
			);
		}

		export default EnvelopeIcon;
	