import React from 'react';
import styled from 'styled-components';
import { space, layout } from 'styled-system';
import Inner from './Styles/Inner';
import SectionDividerTop from './SectionDividerTop';
import SectionDividerBottom from './SectionDividerBottom';
const Section = (props) => {
  const {
    className,
    topDivider,
    bottomDivider,
    dividerTopFill,
    dividerBottomFill,
    ...rest
  } = props;
  return (
    <SectionStyles
      className={className}
      topDivider={topDivider}
      bottomDivider={bottomDivider}
      {...rest}
    >
      {topDivider && <SectionDividerTop fillColor={dividerTopFill} />}
      <Inner className="inner">{props.children}</Inner>
      {bottomDivider && <SectionDividerBottom fillColor={dividerBottomFill} />}
    </SectionStyles>
  );
};

const SectionStyles = styled.section`
  position: relative;
  padding: var(--sectionPadding);
  padding-top: ${({ topDivider }) => topDivider && '0'};
  padding-bottom: ${({ bottomDivider }) => bottomDivider && '0'};
  ${space};
  ${layout};
`;

export default Section;
