import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage, getImage } from 'gatsby-plugin-image';

function Logo({ className }) {
  return (
    <LogoStyles className={(className, `logo`)}>
      <StaticImage
        src="../images/altdev-logo-color.svg"
        placeholder="blurred"
        layout="constrained"
        alt="ALTdev Web Design and Development"
      />
    </LogoStyles>
  );
}

const LogoStyles = styled.div`
  display: flex;
`;

export default Logo;
