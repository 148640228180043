import { createGlobalStyle } from 'styled-components';
import '@fontsource/open-sans';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans';
import '@fontsource/barlow';
import '@fontsource/barlow/400.css';
import '@fontsource/barlow/700.css';
import '@fontsource/barlow/900.css';

const GlobalStyles = createGlobalStyle`

/* Brittany McCauley, without you none of this would be possible. I love you to pluto and back, with all my heart. Forever and ever....and ever. Always. */

:root {
  --primary: var(--blue);
  --secondary: var(--purple);
  --blue: #1E42C8;
  --purple: #5539AC;
  --darkPurple: #1A0C44;
  --seafoam: #1EC89F;
  --accent: var(--seafoam);
  --light: #ffffff;
  --darkBlue: #04091B;
  --dark: #060606;
  --black: #000000;
  --textColor: var(--darkBlue);
  --lightGrey: #fbfbfb;
  --lightGray: var(--lightGrey);
  --lightBlue: #FAFAFF;
  --maxWidth: var(--max-width);
  --sectionPadding: 2em 0;
  --boxShadow: 0px 12px 24px rgba(0,0,0,.09);
  --linearGradientMixed: linear-gradient(120deg, var(--primary) 25%, var(--secondary));
  --max-width--xxs: 38.75rem;
  --max-width--xs: 48.75rem;
  --max-width--sm: 57.50rem;
  --max-width--md: 61.25rem;
  --max-width: 73.75rem;
  --max-width--lg: 80.00rem;
  --max-width--xl: 90.00rem;
  --space-unit: 1em;
  --space-xxxs: calc(0.25*var(--space-unit));
  --space-xxs: calc(0.375*var(--space-unit));
  --space-xs: calc(0.5*var(--space-unit));
  --space-sm: calc(0.75*var(--space-unit));
  --space-md: calc(1.25*var(--space-unit));
  --space-lg: calc(2*var(--space-unit));
  --space-xl: calc(3.25*var(--space-unit));
  --space-xxl: calc(5.25*var(--space-unit));
  --space-xxxl: calc(8.5*var(--space-unit));
  --icon-xs: 16px;
  --icon-sm: 24px;
  --icon-md: 32px;
  --icon-lg: 48px;
  --icon-xl: 64px;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --seafoam: #1EC89F;
    --accent: var(--seafoam);
    --light: #060606;
    --darkBlue: #04091B;
    --dark: #fff;
    --textColor: #fff;
    --lightGrey: #fbfbfb;
    --lightGray: var(--lightGrey);
    --lightBlue: #FAFAFF;
  }
} */

.highlight {
  color: var(--secondary);
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

::selection {
  background: var(--purple);
  color: white;
}

* {
  box-sizing: inherit;
}
*:before {
  box-sizing: inherit;
}
*:after {
  box-sizing: inherit;
}

html {
  font-family: sans-serif;
  font-size: 16px;
  box-sizing: border-box;
}

@media(min-width: 45em) {
  html {
    font-size: 18px;
  }
}

body {
  background: var(--light);
  margin: 0;
  padding: 0;
  /* Can't use with position sticky header */
  /* overflow: hidden; */
  color: var(--textColor);
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  line-height: 1.8;
}

main {
  overflow: hidden;
}

a {
  background-color: transparent;
  text-decoration: none;
  color: var(--blue);
}
a:active,
a:focus,
a:hover {
  /* opacity: 0.75; */
  outline: 0;
  border: none;
}

*[role="button"]:focus {
 outline: 0;
}

img {
  max-width: 100%;
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  line-height: 1.3;
}

h1, .h1 {
  font-size: 3.375rem;
  font-size: clamp(2.25rem, 12vw, 3.375rem);
}
h2, .h2 {
  font-size: 2.25rem;
}
h3, .h3 {
  font-size: 1.5rem;
}
h4, h4 {
  font-size: 1rem;
}
h5, .h5 {
  font-size: 1rem;
}
h6, .h6 {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 0.667rem;
}
.fancyHeadline {
  position: relative;
  display: inline-block;
  &::after {
    content: '';
    position: absolute;
    left: -.5em;
    bottom: 0;
    background: var(--primary);
    border-radius: 1000px;
    opacity: .1;
    width: 110%;
    height: 50%;
  }
}

p {
  margin-top: 0;
  /* max-width: 75ch; */
}

b, strong {
  font-weight: bold;
}
li {
  margin-bottom: calc(1.45rem / 2);
}
ol li {
  padding-left: 0;
}
ul li {
  padding-left: 0;
}
li > ol {
  margin-left: 1.45rem;
  margin-bottom: calc(1.45rem / 2);
  margin-top: calc(1.45rem / 2);
}
li > ul {
  margin-left: 1.45rem;
  margin-bottom: calc(1.45rem / 2);
  margin-top: calc(1.45rem / 2);
}
blockquote *:last-child {
  margin-bottom: 0;
}
li *:last-child {
  margin-bottom: 0;
}
p *:last-child {
  margin-bottom: 0;
}
li > p {
  margin-bottom: calc(1.45rem / 2);
}

.preHeading {
  display: block;
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--primary);
}

.section--purple {
  --primary: var(--accent);
  color: var(--light);
  background: var(--purple);
}

@media only screen and (min-width: 45em) {

}

@media only screen and (min-width: 65em) {
}


`;

export default GlobalStyles;
