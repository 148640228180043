import styled from 'styled-components';
import ButtonBase from './ButtonBase';

const Button = styled(ButtonBase)`
  background: var(--primary);
  :hover {
    background-size: 200% 200%;
    animation: pulse 5s infinite;
  }
  @keyframes pulse {
    0% {
      background-position: 10% 0%;
    }
    50% {
      background-position: 91% 100%;
    }
    100% {
      background-position: 10% 0%;
    }
  }
`;
export default Button;
