import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

function SectionDivider(props) {
  const { fillColor } = props;
  return (
    <SvgStyles
      width="1440"
      height="140"
      viewBox="0 0 1440 140"
      fill="none"
      fillColor={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <g clip-path="url(#clip0)">
        <path d="M1440 0H0V96C288 -44 356.094 119.773 576.5 120C794.913 120.225 1152 -44 1440 96V0Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="1440" height="140" transform="matrix(-1 0 0 1 1440 0)" />
        </clipPath>
      </defs>
    </SvgStyles>
  );
}

const SvgStyles = styled.svg`
  margin-bottom: 2em;
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  fill: ${({ fillColor }) => (fillColor ? fillColor : 'var(--light)')};
`;

export default SectionDivider;
