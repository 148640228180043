import React from 'react'
import styled from 'styled-components'

const FooterColumn = props => {
  return (
    <Column {...props}>
      {props.heading && <h4>{props.heading}</h4>}
      {props.children}
    </Column>
  )
}

const Column = styled.div`
  flex: 1;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  @media (max-width: 720px) {
    & {
      flex: 0 0 100%;
    }
  }
`

export default FooterColumn
