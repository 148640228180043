
		import React from 'react';

		function BarsIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 448, 512">
					<path d="M8 104h432c4.406 0 8-3.594 8-7.1S444.4 88 440 88H8C3.594 88 0 91.59 0 95.1S3.594 104 8 104zM440 248H8C3.594 248 0 251.6 0 256s3.594 8 8 8h432C444.4 264 448 260.4 448 256S444.4 248 440 248zM440 408H8C3.594 408 0 411.6 0 415.1S3.594 424 8 424h432c4.406 0 8-3.594 8-7.1S444.4 408 440 408z" />
				</svg>
			);
		}

		export default BarsIcon;
	