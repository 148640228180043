import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NavItemLink from './NavItemLink';
import Grid from './Styles/Grid';
import Heading from './Styles/Heading';
import ButtonSecondary from './Buttons/ButtonSecondary';

function NavMobile(props) {
  return (
    <NavWrapperStyled {...props}>
      <div>
        <NavMobile__Section>
          <Heading
            fontSize="1.2em"
            color="#56628f"
            fontWeight="300"
            marginBottom="1em"
          >
            Services
          </Heading>
          <GridList
            as="ul"
            gridTemplateColumns={['1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']}
            gridGap={[2, 2, 2]}
          >
            <NavItemLink
              to="/services/web-design"
              linkText="Custom Website Design"
            />
            <NavItemLink
              to="/services/white-label-websites/"
              linkText="White Label Websites"
            />
            <NavItemLink
              to="/services/support-maintenance/"
              linkText="Website Maintenance Plans"
            />
            <NavItemLink
              to="/services/managed-web-hosting/"
              linkText="Managed Hosting"
            />
          </GridList>
        </NavMobile__Section>
        <NavMobile__Section>
          <Heading
            fontSize="1.2em"
            color="#56628f"
            fontWeight="300"
            marginBottom="1em"
          >
            Company
          </Heading>
          <GridList
            as="ul"
            gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
            gridGap={[2, 2, 2]}
          >
            <NavItemLink to="/company/" linkText="About Us" />
            <NavItemLink to="/contact/" linkText="Contact Us" />
          </GridList>
        </NavMobile__Section>
        <NavMobile__Section>
          <Link to="/contact/">
            <ButtonSecondary>Get Started Today</ButtonSecondary>
          </Link>
        </NavMobile__Section>
      </div>
    </NavWrapperStyled>
  );
}

const NavWrapperStyled = styled.div`
  /* display: ${(props) => (props.navActive == true ? 'block' : 'none')}; */
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 2em;
  transform: ${(props) => (props.navActive ? 'scale(1)' : 'scale(0.55)')};
  opacity: ${(props) => (props.navActive ? '1' : '0')};
  visibility: ${(props) => (props.navActive ? 'visible' : 'hidden')};
  transition: transform 0.15s;
  & > div {
    border-top: 2px solid var(--purple);
    position: relative;
    background: white;
    box-shadow: var(--boxShadow);
    border-radius: 5px;
    :before {
      content: '';
      position: absolute;
      top: -1px;
      left: 50%;
      width: 12px;
      height: 12px;
      transform: translate(-50%, -50%) rotate(225deg);
      border-radius: 3px 0 0 0;
      background: white;
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.09);
      border-top: 2px solid var(--purple);
      border-left: 2px solid var(--purple);
      z-index: -1;
      z-index: 2;
    }
  }
  @media (min-width: 81.25em) {
    display: none;
    visibility: hidden;
  }
`;

const NavMobile__Section = styled.section`
  padding: 1.25em;
  & + & {
    /* margin-top: 1em; */
  }
  &:last-of-type {
    background: var(--purple);
  }
`;
const GridList = styled(Grid)`
  padding: 0;
  margin: 0;
`;

const navData = [
  {
    linkText: 'Services',
    location: '/services/',
    dropdown: [
      {
        title: 'Web',
        links: [
          {
            published: true,
            text: 'Custom Website Design',
            location: '/services/web-design',
          },
          {
            published: false,
            text: 'UX Design',
            location: '/services/user-experience/',
          },
        ],
      },
      {
        title: 'Development',
        links: [
          {
            published: true,
            text: 'White Label Websites',
            location: '/services/white-label-websites/',
          },
          {
            published: true,
            text: 'WordPress Development',
            location: '/services/wordpress-development/',
          },
          {
            published: false,
            text: 'Web Applications',
            location: '/services/web-applications/',
          },
        ],
      },
      {
        title: 'Support & Maintenance',
        links: [
          {
            published: true,
            text: 'Website Maintenance Plans',
            location: '/services/support-maintenance/',
          },
          {
            published: true,
            text: 'Managed Hosting',
            location: '/services/managed-web-hosting/',
          },
          {
            published: false,
            text: 'Create Support Ticket',
            location: '/services/managed-web-hosting/',
          },
        ],
      },
    ],
  },
  { linkText: 'Why Us?', location: '#' },
  {
    linkText: 'Who we help',
    location: '#',
    dropdown: [
      {
        title: '',
        links: [
          {
            published: true,
            text: 'Business Owners',
            location: '/businesses',
          },
          {
            published: true,
            text: 'Agencies',
            location: '/agencies',
          },
          {
            published: true,
            text: 'Freelancers',
            location: '/freelancers',
          },
          {
            published: true,
            text: 'Startups',
            location: '/startups',
          },
        ],
      },
    ],
  },
  { linkText: 'Company', location: '/company/' },
  { linkText: 'Contact Us', location: '/contact/' },
  { linkText: 'Blog', location: '/blog/' },
];

export default NavMobile;
