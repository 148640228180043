import styled from 'styled-components';
import { space, layout } from 'styled-system';

const Card = styled.div`
  position: relative;
  color: var(--textColor);
  text-align: left;
  border-radius: 2px;
  padding: ${(props) => (props.padding ? props.padding : '32px')};
  background: #ffffff;
  box-shadow: var(--boxShadow);
  transition: all 0.3s;
  :hover {
    transform: ${(props) => props.link && 'scale(1.04)'};
  }
  ${space};
  ${layout};
`;

export default Card;
