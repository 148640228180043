import React from 'react';
import styled from 'styled-components';
import Section from '../components/Section';
import Typography from './Styles/Typography';
import Form from '../components/Styles/Form';
import Button from '../components/Buttons/Button';
import Card from '../components/Styles/Card';

function FooterForm() {
  return (
    <SectionStyles bottomDivider={true}>
      <Typography as="span" textAlign="center" className="preHeading">
        Ready to get started?
      </Typography>
      <Typography as="h3" textAlign="center" className="h2">
        Tell us about your project
      </Typography>
      <CardStyles>
        <Form
          id="global-contact-form"
          method="POST"
          netlify-honeypot="bot-field"
          data-netlify="true"
        >
          <fieldset>
            <input type="hidden" name="form-name" value="global-contact-form" />
            <div className="input-group input-group--honeypot">
              <label>
                Don’t fill this out if you’re human:
                <input name="bot-field" />
              </label>
            </div>
          </fieldset>

          <fieldset>
            <div className="input-group">
              <label htmlFor="name">
                Name<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                aria-label="text input"
                name="name"
                placeholder="Your Name"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="email">
                Email<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                aria-label="text input"
                name="email"
                placeholder="Your Email"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="message">
                Additional Info...or just say hi :)
              </label>
              <textarea name="message" />
            </div>
            <Button>Request your Estimate</Button>
          </fieldset>
        </Form>
      </CardStyles>
    </SectionStyles>
  );
}

const SectionStyles = styled(Section)`
  background: var(--lightBlue);
`;

const CardStyles = styled(Card)`
  max-width: 640px;
  margin: 0 auto;
`;

export default FooterForm;
