import styled from 'styled-components';

const ButtonBase = styled.button`
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1em 2em;
  border-radius: 50px;
  border: 0;
  transition: all 0.3s;
  background: gray;
  color: white;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  :hover,
  :focus {
    box-shadow: 0px 0px 5px #0093ff;
    outline: none;
  }
`;
export default ButtonBase;
