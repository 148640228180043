import styled from 'styled-components';

const Form = styled.form`
  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
  }
  label {
    display: inline-block;
    margin-bottom: 0.5em;
  }
  textarea,
  input {
    display: block;
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  textarea {
    resize: vertical;
  }
  input[type='radio'] {
    width: 1em;
    display: inline-block;
    margin: 0 0.5em 0 0;
  }
  .input-group {
    margin-bottom: 0.5em;
  }
  .input-group--honeypot {
    height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .input-group--radio {
    label {
      margin: 0;
    }
  }
  .input-group--icon {
    display: flex;
    align-items: stretch;
    width: 100%;
    .icon {
      padding: 0.375rem 0.75rem;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #e9ecef;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export default Form;
